<template>
   <section class="solutions-holder">   
        <v-layout justify-space-around justify-center class="breadcrumbs-box">
            <v-flex xs9 sm9 offset-sm0 class="">
                <v-card justify="right" flat tile :color="color">
                    <v-card-actions class="justify-start">
                        <v-breadcrumbs class="breadcrumb-item"  :items="items" ></v-breadcrumbs>
                        </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
   </section>
</template>

<script>
export default {
    name: 'BreadCrumbs',
    props: ['page', 'items', 'color'],

}
</script>
